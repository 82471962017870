import { Link } from "gatsby"
import styled from "@emotion/styled"

export default styled(Link)`
  ${props => props.theme.typography.body1};
  ${props =>
    !props.main &&
    `
        color: ${props.theme.color.text[props.fontWeight || "high"]};
        `}
  ${props =>
    props.main &&
    `
        font-weight: bold;
        color: white;
        text-decoration-line: none;
        padding: 0.25rem 1rem 0.25rem 0.5rem;
        background-color: ${props.theme.color.primary};
        border-radius: 4px;
        `}
    ${props => `
        display: ${props.display || `inline`};
        text-decoration-line: ${props.hideUnderline ? `none` : `underline`};
        font-weight: ${props.selected ? "bold" : "normal"};
        &:hover{
            color: ${props.theme.color.secondary.dark};
        }
    `}
`

import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Title from "../components/title"
import { Deck, Card, CardTitle, CardBody } from "../components/card"
import Link from "../components/link"

const SpanLink = props => (
  <span>
    <Link {...props} />
  </span>
)

const BriefingCard = props => {
  const { title, slug, group } = props

  return (
    <Card>
      <CardTitle>{title}</CardTitle>
      <CardBody>
        A brief description about section from summary query{" "}
        <SpanLink to={`/${group}/${slug}`}>🎁</SpanLink>
      </CardBody>
    </Card>
  )
}

export default function({ data, pageContext }) {
  const summaries = data.summary.nodes
  const { group } = pageContext
  const { title } = data.alias
  return (
    <Layout>
      <Title>{title}</Title>

      {summaries.map(({ title, slug }) => (
        <BriefingCard key={slug} {...{ group, slug, title }} />
      ))}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($group: String!) {
    summary: allContentSummary(filter: { group: { eq: $group } }) {
      nodes {
        title
        slug
      }
    }

    alias: groupsCsv(group: { eq: $group }) {
      title
    }
  }
`

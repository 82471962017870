import styled from "@emotion/styled";

export default styled.div`
    margin: 8px;
    display: flex;
    flex-wrap: unwrap;
    justify-content: space-around;

    @media (max-width: 800px) {
    flex-wrap: wrap;
    }

    > div {
        margin: 1rem;
    }
    `;
import styled from "@emotion/styled";

export default styled.div`
    ${props => props.theme.typography.headline4};
    ${props => props.divider && `
      border-bottom: 0.25rem solid ${props.theme.color.primary};
      margin-bottom: 1rem;
    `}
    ${props => !props.divider && `
      margin-bottom: 0.5rem;
    `}
    padding-bottom: 0.25rem;
    
    `
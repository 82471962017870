import styled from "@emotion/styled"

export default styled.h1`
  ${props => props.theme.typography.headline1};
  text-transform: capitalize;
  font-weight: bold;
  line-height: 54px;
  margin-bottom: 1rem;
  color: ${props => props.theme.color.text.high};
`
